import { Factory, IBoxProps } from 'native-base'
import React from 'react'
import { Platform } from 'react-native'

import { Box, Text, Stack, Container, Divider, VStack, HStack } from 'ui'
import { useUISecurity } from 'ui/ui-security'

function HTMLForm({ dataSet, children, skey, ...props }: any) {
  const uiSecurity = useUISecurity()
  const onSubmit = uiSecurity.canChange(skey)
    ? props.onSubmit
    : (ev) => {
        !!ev && ev.preventDefault && ev.preventDefault()
      }
  return (
    <form {...props} onSubmit={onSubmit}>
      {children}
      {/* submit button needed to submit the form by pressing Enter key */}
      <input type="submit" tabIndex="-1" hidden={true} style={{ visibility: 'hidden' }} />
    </form>
  )
}

const WebForm = Factory(HTMLForm)
const BaseForm = Platform.OS === 'web' ? WebForm : Container

function Form(props: React.PropsWithChildren<any>): JSX.Element {
  return (
    <BaseForm
      mx={{ base: 4, lg: 'auto' }}
      maxWidth={{ base: '100%', lg: '90%', xl: '80%' }}
      minWidth={{ base: undefined, lg: '90%', xl: '80%' }}
      alignItems="stretch"
      {...props}
    />
  )
}

interface SectionProps {
  title?: string | React.ReactNode
  description?: string
  icon?: JSX.Element
  style?: any
  skey?: string
  narrow?: boolean
}

function Section({
  children,
  title,
  description,
  icon,
  style,
  skey,
  narrow,
}: React.PropsWithChildren<SectionProps>): JSX.Element {
  return (
    <>
      <Stack direction={{ base: 'column', md: 'row' }} py={8} space={6} style={style}>
        <VStack space="2" w={{ base: '100%', md: narrow ? '15%' : '30%' }}>
          <HStack alignItems="center" space={3} pt={1}>
            {!!icon && icon}
            {!!title && (
              <Text fontSize="lg" bold _light={{ color: 'base.900' }} _dark={{ color: 'base.50' }}>
                {title}
              </Text>
            )}
          </HStack>
          {!!description && (
            <Text fontSize={15} _light={{ color: 'base.600' }} _dark={{ color: 'base.400' }}>
              {description}
            </Text>
          )}
        </VStack>
        <Box flex={1}>{children}</Box>
      </Stack>
      <Divider />
    </>
  )
}

function Buttons({ children }: React.PropsWithChildren<any>): JSX.Element {
  return (
    <HStack
      py={8}
      space={6}
      justifyContent={{ base: 'center', md: 'right' }}
      alignItems={{ base: 'initial', md: 'center' }}>
      {children}
    </HStack>
  )
}

Form.Section = Section
Form.Buttons = Buttons

export { Form, BaseForm }
