export const Modal = {
  baseStyle: {
    safearea: true,
  },
  sizes: {
    xs: {
      contentSize: {
        width: { base: '100vw', sm: '60%' },
        maxWidth: { base: '100vw', sm: '280' },
      },
    },
    sm: {
      contentSize: {
        width: { base: '100vw', sm: '65%' },
        maxWidth: { base: '100vw', sm: '320' },
      },
    },
    md: {
      contentSize: {
        width: { base: '100vw', sm: '75%' },
        maxWidth: { base: '100vw', sm: '380' },
      },
    },
    lg: {
      contentSize: {
        width: { base: '100vw', sm: '80%' },
        maxWidth: { base: '100vw', sm: '520' },
      },
    },
    xl: {
      contentSize: {
        width: { base: '100vw', sm: '90%' },
        maxWidth: { base: '100vw', sm: '580' },
      },
    },
    full: {
      contentSize: {
        width: { base: '100vw', sm: '100%' },
      },
    },
  },
}

export const ModalContent = {
  baseStyle: () => ({
    height: { base: '100%', sm: undefined },
    maxHeight: { base: 'none', sm: undefined },
    rounded: { base: 'none', sm: 'lg' },
    bg: 'base.50',
    _text: {
      color: 'base.900',
    },

    _dark: {
      bg: 'base.800',
      _text: {
        color: 'base.50',
      },
    },
  }),
}

export const ModalCloseButton = {
  baseStyle: () => {
    return {
      colorScheme: 'base',
      _icon: {
        color: 'base.500',
      },

      _hover: {
        bg: 'base.200',
      },
      _pressed: {
        bg: 'base.300',
      },

      _dark: {
        _icon: {
          color: 'base.400',
        },
        _hover: {
          bg: 'base.700',
        },
        _pressed: {
          bg: 'base.600',
        },
      },
    }
  },
}

export const ModalHeader = {
  baseStyle: () => {
    return {
      _text: {
        color: 'base.900',
      },

      bg: 'base.50',
      borderColor: 'base.300',

      _dark: {
        bg: 'base.800',
        borderColor: 'base.700',
        _text: {
          color: 'base.50',
        },
      },
    }
  },
}

export const ModalFooter = {
  baseStyle: () => {
    return {
      p: '4',
      flexDirection: 'row',
      justifyContent: 'flex-end',
      flexWrap: 'wrap',
      borderTopWidth: 1,

      bg: 'base.50',
      borderColor: 'base.300',

      _dark: {
        bg: 'base.800',
        borderColor: 'base.700',
      },
    }
  },
}
