import React, { useContext, useEffect } from 'react'
import { PhoenixSocketContext } from './phoenix-channel'
import { QueryClient, QueryKey } from '@tanstack/react-query'

const ACTION = 'shout'

export function QuerySyncService({ queryClient }: { queryClient: QueryClient }) {
  const { channel } = useContext(PhoenixSocketContext)

  useEffect(() => {
    if (!channel) return

    channel.on(ACTION, (payload) => {
      if (payload.invalidateKeys) {
        payload.invalidateKeys.forEach((queryKey: QueryKey) => {
          queryClient.invalidateQueries({ queryKey })
        })
      }
    })

    const mutationCache = queryClient.getMutationCache()

    const unsubscribeFromMutationCache = mutationCache.subscribe((event) => {
      // console.log('mutation success', event)
      if (event.type === 'updated' && event.mutation?.state.status === 'success') {
        if (event.mutation.options.meta?.invalidateKeys) {
          const allKeys = event.mutation.options.meta.invalidateKeys as QueryKey[]

          channel.push(ACTION, { invalidateKeys: allKeys })
        }
      }
    })

    return () => {
      unsubscribeFromMutationCache()
      channel.off(ACTION)
    }
  }, [channel])

  return null
}
